<template>
  <div :class="work_item"
    @click="openWork()"
    v-on:mouseover="mouseover()"
    v-on:mouseleave="mouseleave()"
  >
    <div :class="'bg bg_' + index"></div>
    <div :class="media_inner_class">
      <div class="image_affiche">
        <div class="image" ref="image" :style="'background-image:url(\''+preview_picture_src+'\')'"></div>
      </div>
      <div class="text">
        <div class="text_first">
          <div class="font_anton libelle"><span v-html="libelle"></span></div>
          <div class="font_oswald caption"><span v-html="caption"></span></div>
        </div>
        <div class="logo" v-if="logo"><img :src="logo" /></div>
      </div>
    </div>
  </div>
</template>

<script>
/* <div class="video" v-show="start_video"><div class="video_inner"><video v-if="load_video" ref="video" :onload="videoLoaded()" loop autoplay muted playsinline :src="preview_video_src"></video></div></div> */
import { gsap, Power4 } from 'gsap'
export default {
  name: 'Preview',
  props: ['doc', 'index', 'paire', 'itemIndexOver'],
  components: {
  },
  data () {
    return {
      preview_image: this.doc && this.doc.folder ? this.$store.state.cdn + '/works/' + this.doc.folder + '/' + this.doc.folder + '_preview.jpg' : '',
      preview_video: this.doc && this.doc.folder && !this.$store.state.isMobile ? this.$store.state.cdn + '/works/' + this.doc.folder + '/' + this.doc.folder + '_preview.mp4' : '',
      libelle: this.doc && this.doc.libelle ? this.doc.libelle.replace('<br/>', ' ') : '',
      caption: this.doc && this.doc.caption_1 ? this.doc.caption_1.replace('<br/>', ' ') : '',
      display: this.doc && this.doc.display ? this.doc.display : '',
      logo: this.doc && this.doc.logo ? this.$store.state.cdn + '/images/logos/' + this.doc.logo : '',
      start_video: false,
      video_loaded: false,
      isIntersected: false,
      preview_picture_src: '',
      preview_video_src: '',
      media_inner_class: 'media_inner',
      startAnimDelay: this.index * 0.5,
      animEnd: false,
      pair: this.paire ? ' color2' : ''
    }
  },
  computed: {
    load_video () {
      return this.preview_video !== '' && this.isIntersected && this.animEnd
    },
    work_item () {
      return (!this.itemIndexOver && this.itemIndexOver !== 0) || (this.itemIndexOver === this.index) ? 'work_item' : 'work_item grey'
    }
  },
  mounted: function () {
    this.initObserver()
  },
  destroyed () {
    this.observer.disconnect()
  },
  methods: {
    closePreview () {
      // this.$emit('closePreview')
    },
    openWork () {
      this.$router.push('/' + this.doc.type + '/' + this.display)
    },
    videoLoaded () {
      this.video_loaded = true
    },
    mouseover () {
      this.$emit('ItemMouseOver', this.index)
      if (!this.$store.state.isMobile && this.animEnd && this.video_loaded) this.start_video = true
    },
    mouseleave () {
      this.$emit('ItemMouseLeave', this.index)
      if (!this.$store.state.isMobile && this.animEnd && this.video_loaded) {
        this.start_video = false
        this.$refs.video.currentTime = 0
      }
    },
    onload () {
      this.image_loaded = true
    },
    loadPreview () {
      // console.log(this.startAnimDelay)
      const self = this
      const classN = '.bg_' + this.index
      gsap.timeline()
        .to(classN, { width: '0%', duration: 1.5, ease: Power4.easeOut, onComplete: function () { self.loadMedia() } }, 0)
    },
    loadMedia () {
      const self = this
      const img = new Image()
      img.src = this.preview_image
      img.onload = function () {
        self.preview_picture_src = self.preview_image
        const classN = '.bg_' + self.index
        gsap.timeline()
          .to(classN, { width: '0%', duration: 1.5, ease: Power4.easeOut, onComplete: function () { self.preview_video_src = self.preview_video } }, 0)
        self.media_inner_class = 'media_inner anim'
        setTimeout(function () { self.animEnd = true }, 1200)
        // self.$refs.image.style.opacity = 1
      }
      // this.preview_video_src = this.preview_video
    },
    initObserver () {
      this.observer = new IntersectionObserver(entries => {
        const image = entries[0]
        if (image.isIntersecting) {
          this.isIntersected = true
          this.loadMedia()
          // this.loadPreview()
          this.observer.disconnect()
        }
      }, {
        root: null,
        rootMargin: '-100px',
        threshold: 0
      })
      this.observer.observe(this.$el)
    }
  }
}
</script>

<style lang="scss">

.block_content {
  // justify-content: space-evenly;
  padding:0;
}
.work_item {
  //background: #f1f1f1;
  //background: #141414;
  //aspect-ratio: 16/9;
  //overflow: hidden;
  position: relative;
  flex-basis: calc(100%);
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .bg {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: #141414;
  }
  //background: #586a5e;
  &.color2 {
    //background: #738b7b;
  }
  //transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    // transform: scale(1.02);
  }
  transition: opacity 0.3s;
  &.grey {
    opacity: 0.3;
  }
  .media_inner {
    //background: #4a4a4a;
    // aspect-ratio: 16/10;
    padding: 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /*&::before {
      content: '';
      display: block;
      padding-bottom: 62.5%;
      height: 0;
    }*/
    &::after {
      // content: '';
      position: absolute;
      top: 0;
      left: -100%;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #141414;
      //background: linear-gradient(132deg, rgba(171,214,153,1) 0%, rgb(115, 201, 182) 100%);
    }
    &:hover {
      .image_affiche {
        transform: scale(1.025);
      }
    }
    &.anim {
      &::after {
        //animation: leave 1s forwards cubic-bezier(0.215, 0.610, 0.355, 1);
        //animation: revealColor 1s forwards cubic-bezier(0.215, 0.610, 0.355, 1);
      }
      @keyframes revealColor {
        0% {
          left: -100%;
        }
        50% {
          //top: -50%;
          left: 0;
        }
        100% {
          left: 100%;
        }
      }
      .image {
        // animation: revealImage 0.5s forwards cubic-bezier(0.215, 0.610, 0.355, 1) 0.4s;
        animation: revealImage 0.5s forwards linear 0s;
      }
      @keyframes revealImage {
        0% {
          transform: scale(1.1)
        }
        100% {
          transform: scale(1);
        }
      }
      .text {
        animation: revealText 1s forwards linear 0.6s;
      }
      @keyframes revealText {
        0% {
          opacity: 0
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  cursor: pointer;
  .image_affiche {
    transition: all .3s ease-out;
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-bottom: 62.5%;
      height: 0;
    }
  }
  .image {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    // transform: scale(1.1);
    border-radius: 10px;
    // transition: opacity 0.5s linear;
    //&::after {
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  background: rgba(0,0,0,0.2);
    //}
  }
  .video {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .video_inner {
      position: relative;
      width: 100%;
      height: 100%;
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .text {
    // position: absolute;
    position: relative;
    color: #fff;
    width: 100%;
    //padding-top: 15px;
    z-index: 3;
    //padding-left: 30px;
    // padding-right: 30px;
    padding-bottom: 0px;
    padding-top: 20px;
    // position: relative;
    // display: none;
    opacity: 0;
    &::after {
      // content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 200%;
      background-color: transparent;
      background-image: -webkit-linear-gradient(top, transparent 0%,rgba(0,0,0,0.4) 100%);
      background-image: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,0.4) 100%);
      z-index: -1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text_first {
      padding-left: 0px;
      padding-right: 20px;
      line-height: 1.5em;
      .libelle {
        line-height: 1.2em;
      }
    }
    .logo {
      padding-right: 30px;
      max-width: 50px;
      img {
        width: 100%;
      }
    }
  }
  .libelle {
    font-size: 1.1em;
    line-height: 1em;
    letter-spacing: 0.01em;
  }
  .caption {
    letter-spacing: 0.04em;
  }
}

@media screen and (min-width: 42em) {
  .work_item {
    flex-basis: calc(100% / 2);
    .libelle {
      font-size: 1.2em;
    }
  }
}
@media screen and (min-width: 60em) {
  .work_item {
    flex-basis: calc(100% / 2);
    .text {
      .logo {
        max-width: 60px;
      }
    }
    .libelle {
      font-size: 1.3em;
    }
  }
}
@media screen and (min-width: 72em) {
  .block_content {
    //padding: 0px 15px;
    //padding-top: 40px;
    //padding-bottom: 40px;
    //background: #303030;
    //background: #303030;
    //justify-content: space-around;
  }
  .work_item {
    //flex-basis: calc(100% / 3 - 30px);
    //margin-bottom: 30px;
    flex-basis: calc(100% / 3);
    .text {
      .logo {
        max-width: 70px;
      }
    }
   // margin-bottom: 30px;
  }
}
@media screen and (min-width: 110em) {
  .work_item {
    flex-basis: calc(100% / 3);
  }
}

</style>
