import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/brand',
    component: () => import('../views/Brand.Layout.vue'),
    children: [
      {
        path: '/',
        name: 'brand',
        component: () => import('../views/Brand.vue')
      },
      {
        path: ':id',
        name: 'brand.projet',
        component: () => import('../views/Projet.vue')
      }
    ]
  },
  {
    path: '/fiction',
    component: () => import('../views/Fiction.Layout.vue'),
    children: [
      {
        path: '/',
        name: 'fiction',
        component: () => import('../views/Fiction.vue')
      },
      {
        path: ':id',
        name: 'video.projet',
        component: () => import('../views/Projet.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/:id',
    name: 'Projet.id',
    component: () => import('../views/Projet.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  /* scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  } */
})

router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0)
  // console.log('ROURE FROM')
  // console.log(from)
  // console.log('ROURE TO')
  // console.log(to)
  // // PROJET
  // if (to.name === 'projet') {
  //   const display = to.params.id
  //   console.log('PROJET ASKED - ' + display)
  // }
  // console.log('page TO')
  // console.log(to)
  // let page = ' | ' + to.name.charAt(0).toUpperCase() + to.name.slice(1)
  // if (page === ' | Home') page = ''
  // if (page === ' | Brand.projet') page = ' | Brand'
  // if (page === ' | Video.projet') page = ' | Fiction'
  // const param = to.params && to.params.id ? ' | ' + to.params.id.charAt(0).toUpperCase() + to.params.id.slice(1) : ''
  // document.title = 'Crazymage | Production Audiovisuelle' + page + param
  // document.querySelector('head meta[name="description"]').setAttribute('content', 'test content')
  next()
})

export default router
