<template>
  <div class="home">
    <div class="blocks">
      <div class="block section_1">
        <div class="block_contain block_contain_logo">
          <div class="bg_video">
            <div class="bg_video_reveal bg_video_reveal_left"></div>
            <div class="bg_video_reveal bg_video_reveal_right"></div>
            <div class="video_loader_outer"><div class="video_loader"></div></div>
            <div class="bg_video_wrapper">
              <video :src="first_video_src" :onload="firstVideoLoaded()" loop autoplay muted playsinline></video>
            </div>
          </div>
          <div class="block_contain_inner">
            <div class="block_logo" style="display:none">
              <img :src="require('@/assets/images/crazymage-logo-white.png')" />
            </div>
          </div>
        </div>
      </div>
      <div class="section_2">
        <div class="block_inner">
          <div class="big_words">
            <div class="bg_word font_bebas">
              <a class="bg_word_one">BRAND</a>
              <div class="calltoaction font_oswald"><a @click="$router.push('/brand')">Voir tous nos films de marque</a></div>
            </div>
            <div class="big_words_inner">
              <a class="big_word font_bebas">Publicité</a>
              <a class="big_word font_bebas">Institutionnel</a>
              <a class="big_word font_bebas">Web-Série</a>
              <a class="big_word font_bebas">Court Métrage</a>
              <a class="big_word font_bebas">Documentaire</a>
              <a class="big_word font_bebas">Live</a>
            </div>
          </div>
          <div class="block_inner_text">
            <div class="block_inner_text_content">
              <div class="text font_oswald" v-html="block_1"></div>
              <div class="text font_oswald"  v-html="block_2"></div>
            </div>
          </div>
          <div class="block_content">
            <Preview
              v-for="(work, index) in works"
              :key="index"
              :index="index"
              :doc="work"
              :paire="index%2"
              v-bind:itemIndexOver="itemIndexOver"
              v-on:ItemMouseOver="ItemMouseOver"
              v-on:ItemMouseLeave="ItemMouseLeave"
            />
          </div>
        </div>
      </div>
      <div class="block section_1">
        <div class="block_contain block_contain_logo">
          <div class="bg_video">
            <div class="bg_video_reveal bg_video_reveal_left"></div>
            <div class="bg_video_reveal bg_video_reveal_right"></div>
            <div class="video_loader_outer"><div class="video_loader"></div></div>
            <div class="bg_video_wrapper">
              <video :src="first_video_src_2" :onload="firstVideoLoaded()" loop autoplay muted playsinline></video>
            </div>
          </div>
          <div class="block_contain_inner">
            <div class="block_logo" style="display:none">
              <img :src="require('@/assets/images/crazymage-logo-white.png')" />
            </div>
          </div>
        </div>
      </div>
      <div class="section_3">
        <div class="block_inner">
          <div class="big_words">
            <div class="bg_word font_bebas">
              <a class="bg_word_one">FICTION</a>
              <div class="calltoaction font_oswald"><a @click="$router.push('/fiction')">Voir tous nos films</a></div>
            </div>
            <div class="big_words_inner">
              <a class="big_word font_bebas">Cinema</a>
              <a class="big_word font_bebas">Court métrage</a>
              <a class="big_word font_bebas">Web-série</a>
              <a class="big_word font_bebas">Documentaire</a>
              <a class="big_word font_bebas">Série TV</a>
            </div>
          </div>
          <div class="block_inner_text">
            <div class="block_inner_text_content">
              <div class="text font_oswald" v-html="blockF_1"></div>
              <div class="text font_oswald"  v-html="blockF_2"></div>
            </div>
          </div>
          <div class="add_paddingTop"></div>
          <div class="block_content">
            <PreviewF
              v-for="(work, index) in fiction"
              :key="index+10"
              :index="index+10"
              :doc="work"
              :paire="index%2"
              v-bind:itemIndexOver="itemIndexOver"
              v-on:ItemMouseOver="ItemMouseOver"
              v-on:ItemMouseLeave="ItemMouseLeave"
            />
          </div>
        </div>
      </div>
      <div class="contact" style="display:none;">
        <div class="abs_background abs_background_2">
          <div class="abs_inner" :style="{ backgroundImage: 'url(' + require('@/assets/images/ui/about_2.jpg') + ')' }"></div>
        </div>
        <div class="contact_inner">
          <div class="font_anton mail"><a :href="'mailto:' + 'hello@crazymage.fr'">hello@crazymage.fr</a></div>
        </div>
      </div>
      <div class="teams" style="display:none;">
        <div class="block_inner_text_content">
          <div class="text font_oswald"  v-html="blockF_2"></div>
        </div>
        <div class="teams_inner">
          <div class="team">
            <img :src="require('@/assets/images/ui/raphael.jpg')" />
            <div class="prenom font_anton">Raphaël</div>
            <div class="metier font_oswald">Producteur</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'raphael@crazymage.fr'">raphael@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/clem.jpg')" />
            <div class="prenom font_anton">Clémence</div>
            <div class="metier font_oswald">Directrice Financière</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'c.devisme@crazymage.fr'">c.devisme@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/axel.jpg')" />
            <div class="prenom font_anton">Axel</div>
            <div class="metier font_oswald">Directeur Artistique</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'axel@crazymage.fr'">axel@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/fanny.jpg')" />
            <div class="prenom font_anton">Fanny</div>
            <div class="metier font_oswald">Productrice Exécutive</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'fanny@crazymage.fr'">fanny@crazymage.fr</a></div>
          </div>
        </div>
      </div>
      <div class="clients" style="display:none;">
        <div class="logos_outer">
          <div class="logos">
            <a
              v-for="(client, index) in clients"
              :key="index"
              class="logo_client"
            ><img :src="cdn + '/images/logos/' + client.logo" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Preview from '@/components/Preview'
import PreviewF from '@/components/PreviewFiction'
import _ from 'lodash'
import { gsap, Power4 } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)
export default {
  name: 'Home',
  metaInfo () {
    return {
      title: this.$store.state.meta.basic,
      meta: [
        { name: 'robots', content: this.$store.state.meta.robots },
        { name: 'description', content: this.$store.state.meta.description },
        { property: 'og:title', content: this.$store.state.meta.og_title },
        { property: 'og:site_name', content: this.$store.state.meta.og_site_name },
        { property: 'og:type', content: this.$store.state.meta.og_type },
        { property: 'og:url', content: this.$store.state.meta.og_url },
        { property: 'og:image:type', content: this.$store.state.meta.og_image_type },
        { property: 'og:image', content: this.$store.state.meta.og_image }
      ]
    }
  },
  components: {
    Preview,
    PreviewF
  },
  data () {
    return {
      cdn: this.$store.state.cdn,
      works: [],
      brand: [],
      brandFocus: {},
      fiction: [],
      scrollActive: true,
      first_video_src: '',
      first_video_src_2: '',
      itemIndexOver: null
    }
  },
  beforeRouteEnter (to, from, next) {
    window.scrollTo(0, 0)
    next(vm => {
      // vm.$store.state.loading_time_after = 2200
      vm.$store.state.loading_time_after = 0
      vm.$store.state.loading_direct = true
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('HOME - before route LEAVE')
    this.$store.state.loading_open = true // TO DELETE ?
    this.$store.state.loading_leave = true
    // LOADING TITLE
    if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
    else if (to.name === 'projet') this.$store.state.loading_title = 'work'
    else this.$store.state.loading_title = to.name
    setTimeout(function () {
      next()
    }, this.$store.state.loading_time_before)
  },
  created () {
    const works = this.$store.state.works
    // this.works = _.orderBy(_.filter(works, function (w) { return w.actif && w.home_display }), function (e) { return parseInt(e.home_order) }, ['asc'])
    this.works = _.filter(_.orderBy(_.filter(works, function (w) { return w.actif && w.type === 'brand' && w.order && w.order > 0 }), function (e) { return parseInt(e.order) }, ['asc']), function (i, index) {
      return index < 9
    })

    this.fiction = _.filter(_.orderBy(_.filter(works, function (w) { return w.actif && w.type === 'fiction' && w.order && w.order > 0 }), function (e) { return parseInt(e.order) }, ['asc']), function (i, index) {
      return index < 9
    })
    // const bwWithOrder = _.orderBy(_.filter(brandworks, function (w) { return w.order && w.order > 0 }), function (e) { return parseInt(e.order) }, ['asc'])
    // this.fiction = _.filter(this.works, function (w) { return w.type === 'fiction' })
  },
  /*
  1. les medias a loader avant la fin du loader
  2. les animations d'arrivée
  3. les animations de navigation
  4. les animation de sortie avant la prochaine page
  */
  mounted: function () {
    // this.scrollSection()
    console.log('mounted')
    const self = this
    if (!this.$store.state.loading_first) {
      setTimeout(function () {
        self.$store.state.loading_open = false
      }, this.$store.state.loading_time_after)
    } else {
      // this.$store.state.loading_open = false
    }
    const timeStartAnim = this.$store.state.loading_first ? 2500 : 0
    const home = _.find(this.$store.state.about, function (e) { return e.type === 'home' })
    setTimeout(function () {
      self.first_video_src = self.cdn + '/works/home/' + home.video_1_url + '?v=' + home.video_1
      self.first_video_src_2 = self.cdn + '/works/home/' + home.video_2_url + '?v=' + home.video_2
      //  + self.$store.state.about.home.video_1
      // self.ready = true
      // self.firstSectionAnim()
    }, timeStartAnim)
    this.bg_scroll()
    // window.addEventListener('resize', () => {
    //   this.resizeSectionHeight()
    // })
    // this.resizeSectionHeight()
  },
  methods: {
    navigationVideo (item) {
      const offset = this.$refs[item][0].offsetTop - 80
      console.log(offset)
      gsap.to(window, { duration: 2, scrollTo: offset })
    },
    resizeSectionHeight () {
      const vh = window.innerHeight - 80
      // this.$refs.section_1.style.setProperty('height', `${vh}px`)
      gsap.to('.block', { height: vh + 'px', duration: 0 })
    },
    firstVideoLoaded () {
      const timeToWait = this.$store.state.loading_first ? 2500 : 200
      setTimeout(function () {
        gsap.timeline()
          .to('.video_loader_outer', { opacity: 0 })
          .to('.bg_video_reveal_left', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_reveal_right', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_wrapper', { scale: 1, duration: 1, ease: 'linear' }, 0)
          .to('.bg_title_container', { opacity: 1, duration: 0.5, ease: 'linear' }, 0.7)
      }, timeToWait)
    },
    firstSectionAnim () {
      gsap.timeline()
        .to('.bg_title_container', { opacity: 1, duration: 0.5, ease: 'linear' }, 2)
    },
    scrollSection () {
      const self = this
      const panels = gsap.utils.toArray('.block')
      // const scrollTween = this.scrollTween
      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: 'top bottom',
          end: 'bottom',
          // onToggle: self => self.isActive && !scrollTween && _.goToSection(i),
          onToggle: function (el) {
            if (el.isActive && self.scrollActive) {
              self.scrollActive = false
              self.goToSection(i)
            }
            // self.goToSection(i)
          },
          scrub: true,
          pin: false,
          pinSpacing: false,
          markers: false
        })
      })
      // ScrollTrigger.create({
      //   start: 0,
      //   end: 'max',
      //   snap: 1 / (panels.length - 1)
      // })
    },
    goToSectionOld (i) {
      // const self = this
      console.log('enter section !')
      console.log(i)
      console.log(window.innerHeight)
      gsap.to(window, {
        // scrollTo: { y: i * innerHeight, autoKill: false },
        scrollTo: { y: i * innerHeight },
        duration: 1,
        onComplete: function () {},
        overwrite: true
      })
    },
    goToSection (i) {
      const self = this
      console.log(i)
      const hauteur = window.innerHeight
      const hauteurF = (hauteur * i) + (80 * i) + 1
      console.log(hauteurF)
      // this.scrollActive = true
      gsap.to(window, {
        scrollTo: { y: hauteurF, autoKill: false },
        duration: 1,
        onComplete: function () { self.scrollActive = true }
        // overwrite: false
      })
    },
    ItemMouseOver (id) {
      this.itemIndexOver = id
    },
    ItemMouseLeave (id) {
      this.itemIndexOver = null
    },
    bg_scroll () {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.contact',
          start: 'top bottom',
          end: 'bottom top',
          markers: false,
          scrub: true,
          pin: false
        }
      }).to('.abs_background_2', { y: innerHeight / 2 * 0.14 }, 0)
    }
  },
  computed: {
    clients () {
      return _.orderBy(this.$store.state.clients, function (e) { return parseInt(e.ordre) }, ['asc'])
    },
    adn () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'adn' })
      return aboutAdn.text
    },
    block_1 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'block_1' })
      return aboutAdn.text
    },
    block_2 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'block_2' })
      return aboutAdn.text
    },
    blockF_1 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'blockF_1' })
      return aboutAdn.text
    },
    blockF_2 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'blockF_2' })
      return aboutAdn.text
    }
    // works () {
    //   const works = this.$store.state.works
    //   return _.orderBy(_.filter(works, function (w) { return w.actif && w.home_display }), function (e) { return parseInt(e.home_order) }, ['asc'])
    // },
    // cdn () {
    //   return this.$store.state.cdn
    // }
  }
}
</script>

<style lang="scss" scoped>
.home {
}
.nav_videos {
  position: fixed;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 990;
  .nav_video {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: 0.8em;
    border: 1px solid #fff;
    cursor: pointer;
    &:nth-child(1) {
      margin-top: 0;
    }
    &::after {
      content: '';
      position: absolute;
      background: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 65%;
      width: 65%;
      border-radius: 50%;
    }
  }
}
.blocks {
  display:flex;
  flex-direction: column;
  //padding: 0 40px;
  padding-top: 80px;
  padding-bottom: 40px;
  &.blocks_preview {
    padding-left: 0;
    padding-right: 0;
  }
  //scroll-snap-type: y mandatory;
}
.block {

  width:calc(100%);
  //flex-basis: calc(100%);
  // height:calc(100vh - 80px);
  height:60vh;
  // transition: height 0.4s linear;
  &:nth-child(1) {
    // width: calc(100% - 50px);
    // padding: 0 25px;
  }

  &.block_ref {
    cursor: pointer;
  }
  //height:100vh;
  //scroll-snap-align: start;

  .block_contain {

    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    position:relative;

    &.block_contain_preview {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .bg_video {
      // position:absolute;
      // top:0px;
      // left:0px;
      position: relative;
      width:100%;
      height:100%;
      z-index:0;
      overflow: hidden;
      &.bg_video_preview {
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
      }
      .bg_video_reveal {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 2;
        background: #141414;
        // background: red;
        &.bg_video_reveal_left {
          //background: red;
          left: 0;
        }
        &.bg_video_reveal_right {
          //background: green;
          right: 0;
        }
      }
      .video_loader_outer {
        position: absolute;
        z-index: 3;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        opacity: 1;
        top: 0;
        left: 0;
      }
      .video_loader,
      .video_loader:before,
      .video_loader:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
      }
      .video_loader {
        color: #333;
        font-size: 6px;
        margin-top: -2.5em;
        // margin: 80px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .video_loader:before,
      .video_loader:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .video_loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .video_loader:after {
        left: 3.5em;
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      .bg_video_wrapper {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        transform: scale(1.1);
        z-index: 1;
        video {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
    .block_contain_inner {
      z-index:1;
      color:#fff;
      padding-bottom: 4em;
      padding-right: 2em;
      opacity:0.95;
      .block_text_desc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .text_desc_logo {
        padding-left: 15px;
        //display: flex;
        img {
          width: 60px;
          height: auto;
          max-width: 60px;
        }
      }
      .text_desc {
        font-size:0.9em;
        letter-spacing: 0.02em;
        text-align:right;
        padding-left:20px;
        line-height: 1.3em;
      }
      .block_libelle {
        display:flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content:flex-end;
        cursor: pointer;
        .text_title {
          color:#fff;
          font-size:2.5em;
          line-height: 1.2em;
          letter-spacing: 0.01em;
          text-align: right;
          margin-right: -2px;
          position: relative;
          /* &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: -1%;
            // transform: translate(-50%,0);
            background-color: red;
            width: 0%;
            height: 35%;
            z-index: -1;
            opacity: 0.6;
            transition: all 0.8s cubic-bezier(.43,1.1,.74,.98);
          }
          &:hover {
            &::after {
              width: 102%;
            }
          } */
        }
      }
    }
    .bg_title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .bg_title_container {
        //font-size: 10em;
        //line-height: 0.85em;
        transition: color 0.5s linear 0s;
        opacity: 0;
        text-align:center;
        //&:hover {
        //  color: #555;
        //}
        a {
          display: inline-block;
          cursor: pointer;
          font-size: 15vw;
          line-height: 15vw;
          letter-spacing: 0.02em;
          transition: color 0.5s linear 0s;
          &:nth-child(2) {
            color: #141414;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              background: #fff;
              height: 2vw;
              width: 2vw;
              border-radius: 50%;
              top: 45%;
              left: 50%;
              transform: translate(-50%,-60%);
            }
            //margin-left: -0.04em;
          }
          &:nth-child(3) {
            //margin-right: -0.00em;
          }
          //&:hover {
          //  color: #fff;
          //}
        }
      }
    }

    &.block_contain_logo {
      // align-items: flex-start;
      // justify-content: center;
      .block_contain_inner {
        padding:0;
        .block_logo {
          text-align: center;
          img {
            width: 90%;
            max-width: 200px;
          }
        }
      }
    }

  }
}
.block_links {
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  justify-content:space-between;
  min-height:300px;
  .block_link {
    cursor:pointer;
    font-size:3em;
    color:#fff;
    font-weight:400;
    justify-content:center;
    align-items:center;
    flex-grow:1;
    display:flex;
    background-color:#000;
    /*&:nth-child(2) {
      background-color:#fff;
      color:#000;
    }*/
    &:hover {
      background-color:#fff;
      color:#000;
    }
  }
}
.block_inner {
    background-color:#141414;
    padding: 40px 25px;
    padding-bottom: 0px;
    padding-top: 30px;
    .add_paddingTop {
      padding-top: 20px;
    }
    .block_inner_text {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      .block_inner_text_content {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        flex-direction: column;
        .text {
          font-size:1.3em;
          flex-basis: 100%;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
    .big_words {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      padding-top: 20px;
      flex-direction: column;
      align-items: flex-start;
      .bg_word {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
        .bg_word_one {
          font-size: 7em;
          line-height: 0.65em;
        }
      }
      .big_words_inner {
        max-width: 600px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-top: 30px;
        .big_word {
          font-size:2.2em;
          line-height: 1em;
          padding: 0 20px 0 0px;
        }
      }
    }
    .calltoaction {
      a {
        cursor: pointer;
        font-size: 1.2em;
        transition: opacity 0.3s;
        padding-left: 3px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .block_content {
      display: flex;
      flex-wrap: wrap;
    }
}

.teams {
  background: #141414;
  padding: 60px 25px 60px 25px;
  .block_inner_text_content {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
    .text {
      max-width: 820px;
      text-align: center;
      font-size:1.3em;
      flex-basis: 100%;
    }
  }
  .teams_inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .team {
      width: 50%;
      &:nth-child(3), &:nth-child(4) {
        padding-top: 30px;
      }
      img { width: 100%; padding-bottom: 15px; }
      .prenom { color: #fff; font-size: 1.2em; letter-spacing: 0.05em; font-weight: 300; line-height: 1em;
        a {
          color: #fff;
          text-decoration: none;
          span {
            font-size: 0.8em;
            color: #ccc;
          }
        }
      }
      .metier { color: #fff; letter-spacing: 0.05em; }
      .person_contact {
        color: #fff; letter-spacing: 0.05em;
        a {
          span {padding-right: 4px;}
          color: #ccc;
          text-decoration: none;
        }
      }
    }
  }
}
.clients {
  background: #141414;
  padding: 40px 25px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 3em;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  .logos_outer {padding-top: 60px;}
  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1100px;
    .logo_client {
      // padding: 0 20px;
      width: 33.33%;
      display: flex;
      justify-content: center;
      img { width: 70%; max-width: 150px; }
    }
  }
}

.abs_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .abs_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #000;
      z-index: 0;
      opacity: 0.4;
    }
  }
  &.abs_background_2 {.abs_inner {width: 120%; height: 120%;}}
}
.contact {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  //background-attachment: fixed;
  padding: 150px 30px;
  margin: 20px 0 0 0;
  &::after {
    //content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: 0;
    opacity: 0.4;
  }
  .contact_inner {
    position: relative;
    z-index: 1;
    color: #fff;
    .mail {
      color:#141414;
      display: flex;
      justify-content: center;
      a {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        font-size: 2em;
        letter-spacing: 0.03em;
        padding: 10px 20px;
        border-radius: 10px;
      }
    }
  }
}

.section_2 {
  padding-bottom: 20px;
}

@media screen and (min-width: 57em) {
  .block {
    &:nth-child(1) {
      // width: calc(100% - 80px);
      // padding: 0 40px;
    }
    .block_contain {
      .bg_video {
        height: 100%;
      }
      .block_contain_inner {
        padding-bottom: 2em;
        .block_libelle {
          .text_title {
            font-size:3em;
            line-height: 1.2em;
          }
        }
      }
      .bg_title {
        .bg_title_container {
          a {
            font-size: 17.5vw;
            line-height: 17.5vw;
            letter-spacing: 0.00em;
          }
        }
      }
      &.block_contain_logo {
        .block_contain_inner {
          .block_logo {
            img {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
  .block_links {
    min-height:250px;
    flex-direction: row;
    .block_link {
      font-size:6.5em;
    }
  }
  .block_inner {
    padding: 10px 25px;
    padding-top: 40px;
    .add_paddingTop {
      padding-top: 20px;
    }
    .block_inner_text {
      padding-bottom: 30px;
      margin-top: -20px;
      .block_inner_text_content {
        max-width: 820px;
        flex-direction: row;
        .text {
          font-size:1.2em;
          flex-basis: 49%;
          &:nth-child(2) {
            display: inherit;
          }
        }
      }
    }
    .big_words {
      padding-bottom: 15px;
      padding-top: 0px;
      flex-direction: row;
      align-items: center;
      .bg_word {
        .bg_word_one {
          font-size: 11em;
          line-height: 0.65em;
        }
      }
      .big_words_inner {
        padding-bottom: 70px;
        padding-left: 20px;
        max-width: 450px;
        .big_word {
          font-size:3em;
          line-height: 0.9em;
          padding: 0 12px;
        }
      }
    }
    .calltoaction {
      a {
        font-size: 1.2em;
        padding-left: 10px;
      }
    }
  }
  .clients {
    .title {font-size: 5em;}
    .logos {.logo_client{width:16.667%;img{width: 80%;}}}
  }
  .contact { .contact_inner { .mail { a { font-size: 3em; } } } }

  .teams {
    .teams_inner {
      flex-wrap: nowrap;
      .team {
        &:nth-child(3), &:nth-child(4) {padding-top:0;}
        width: auto;
        img { width: 100%; max-width: 250px;}
        .prenom { font-size: 1.2em; }
        .metier {  }
      }
    }
  }

}

@media screen and (min-width: 75em) {
  .block {
    .block_contain {
      .bg_video {
        height: 100%;
      }
      .bg_title {
        .bg_title_container {
          a {
            font-size: 18.5vw;
            //line-height: 18.5vw;
            letter-spacing: 0.00em;
            padding-top: 0.1em;
          }
        }
      }
    }
  }
  .block_links {
    min-height:350px;
    flex-direction: row;
    .block_link {
      font-size:8.5em;
    }
  }
  .block_inner {
    padding: 40px 25px;
    padding-top: 15px;
    .add_paddingTop {
      padding-top: 20px;
    }
    .block_inner_text {
      padding-bottom: 60px;
      margin-top: -25px;
      .block_inner_text_content {
        max-width: 1300px;
        .text {
          font-size:1.7em;
          flex-basis: 49%;
        }
      }
    }
    .big_words {
      padding-bottom: 30px;
      padding-top: 30px;
      .bg_word {
        .bg_word_one {
          font-size: 15em;
          line-height: 0.65em;
        }
      }
      .big_words_inner {
        padding-bottom: 70px;
        padding-left: 20px;
        max-width: 600px;
        .big_word {
          font-size:4em;
          line-height: 0.9em;
          padding: 0 12px;
        }
      }
    }
    .calltoaction {
      a {
        font-size: 1.2em;
        padding-left: 10px;
      }
    }
  }
  .contact { .contact_inner { .mail { a { font-size: 4em; } } } }

  .teams {
    .teams_inner {
      .team {
        .prenom { font-size: 1.5em; }
        .metier {  }
      }
    }
  }

}

@media screen and (min-width: 72em) {
  .block {
    .block_contain {
      .bg_title {
        .bg_title_container {
          a {
            font-size: 19vw;
            line-height: 19vw;
            letter-spacing: 0.005em;
            margin-bottom: -0.1em;
            padding-top: 0em;
          }
        }
      }
    }
  }
  .block_links {
    min-height:400px;
    flex-direction: row;
    .block_link {
      font-size:10em;
    }
  }
}

@media screen and (min-width: 110em) {
  .block {
    .block_contain {
      .bg_title {
        .bg_title_container {
          a {
            margin-bottom: -0.1em;
            font-size: 19vw;
            line-height: 19vw;
            letter-spacing: 0.017em;
          }
        }
      }
    }
  }
}

</style>
