import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading_first: true,
    loading_open: false,
    loading_leave: false,
    loading_time_before: 1000, // 950
    loading_time_after: 0,
    loading_direct: false,
    loading_title: 'crazymage',
    isMobile: false,
    cdn: 'https://cdn.crazymage.fr',
    works: [],
    clients: [],
    about: [],
    meta: {
      robots: 'index,follow',
      description: 'We make stories. Brand. Narrative Content. Commercials. Motion Design. Films. Series. Shorts',
      basic: 'Crazymage | Production Audiovisuelle',
      og_title: 'Crazymage | Production Audiovisuelle',
      og_site_name: 'Crazymage',
      og_type: 'website',
      og_url: 'https://www.crazymage.fr/',
      og_image_type: 'image/jpeg',
      og_image: '',
      keywords: 'crazymage, we make stories, motion, motion design, société de production bordeaux, réalisateur bordeaux, faire un film, prod paris, agence paris, cherche une boite de prod, créatif, monteur bordeaux, bordeaux, crazy, image, mage, crazimage, crazyimage, raphael mitelberg, raphael, mitelberg, cinema, television, web, web serie, tele, tv, cine, films, film, serie, magie, devisme, de visme, instit, institutionnel, publicite, pub, creatif, studio, studio creatif, brand content, brand, marque, development, developpement web, web, programmation, php, javascript, css, html, geek, codeur, camera, appareil photo, idee, objectif, tournage, post production, montage, etalonage, mixage son, chef operateur, realisateur, producteur, scenariste, scenario'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
