import Vue from 'vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import VueMeta from 'vue-meta'

// Vue.use(VueMeta)

Vue.config.productionTip = false

const config = {
  apiKey: 'AIzaSyDx1Shy9bZyo6h-9cB4K1lQNxOaPeHmub4',
  authDomain: 'crazymage03-9d671.firebaseapp.com',
  projectId: 'crazymage03-9d671',
  storageBucket: 'crazymage03-9d671.appspot.com',
  messagingSenderId: '331711284710',
  appId: '1:331711284710:web:f753f1970d8511258a8227',
  measurementId: 'G-YE22T3G7HQ'
}

const app = firebase.initializeApp(config)
const db = app.firestore()
Vue.prototype.$db = db

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
